<template>
  <div
      class="center"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
  >
    <div class="header flex">
      <div class="header_left flex">
        <div class="imgs">
          <img
              :src="basicList.imageurl"
              alt
              :class="basicList.imageurl ? 'avatar' : 'err_avatar'"
              v-if="basicList.imageurl"
          />
          <img
              v-else
              src="~static/column/default.png"
              alt
              class="avatar err_avatar"
          />
        </div>
        <ul class="info">
          <li class="flex">
            <h1>{{ basicList.userName }}</h1>
          </li>
          <!-- <li class="edu">
            <span>
              {{ basicList.sex }}
            </span>
            <span v-if="basicList.age">
              <span>/</span>
              {{ basicList.age }}岁
            </span>
            <span v-if="basicList.edu"><span>/</span>{{ basicList.edu }}</span>
            <span v-if="basicList.exp">{{ basicList.exp }}</span>
          </li>
          <li
            class="level"
            v-for="item in basicList.disabledType"
            :key="item.index"
          >
            <span>
              {{ item.disabledType }}
              <span>/</span>
            </span>
            <span>
              {{ item.disabledLevel }}
              <span>/</span>
            </span>
            <span>{{ item.disabledDetail }}</span>
          </li>
          <li class="address">
            <span
              >户籍地：{{ basicList.provinceid }}{{ basicList.cityid }}</span
            >
          </li> -->
        </ul>
      </div>
      <div class="header_right flex">
        <div>
          <h2>14<small>条</small></h2>
          <p>圈子问答</p>
        </div>
        <div class="link">
          <router-link to="/userLogin/authentication" class="certify" style="display: block;margin-top: 10px"
          >认证残疾人找寻好工作</router-link
          >
          <router-link to="/companyLogin/authentication" class="certify" style="display: block;margin-top: 10px"
          >认证企业 <i class="el-icon el-icon-right"></i></router-link
          >
        </div>
      </div>
    </div>
    <!-- 为您推荐的职位 -->
    <div class="section_box work">
      <div class="title relative">
        <h2>为您推荐职位</h2>
        <!-- <router-link @click="change()" class="all"> -->
        <router-link to="/job" class="p1 all" target="_blank">查看更多</router-link>
        <!-- </router-link> -->
      </div>
      <ul class="job flex flex-wrap">
        <li v-for="item in info" :key="item.id" class="flex">
          <div class="top">
            <router-link
                :to="'/job/jobDetail?id=' + item.id"
                target="_blank"
                class="line_clamp1"
                :title="item.jobName"
            >
              <div class="flex nameWrap">
                <div class="name relative line_clamp1">
                  {{ item.jobName }}
                </div>
                <p class="jobtimes">
                  「{{ item.updateTime | currentYear(item.updateTime) }}」
                </p>
                <p class="money">{{ item.jobSalary || "面议" }}</p>
              </div>
              <div class="address line_clamp1">
                <span v-if="item.cityid">
                  {{ item.cityid }}
                  <span v-if="item.threeCityid">- {{ item.threeCityid }}</span>
                </span>
                <span v-else> 城市不限 </span>
                <el-divider direction="vertical"></el-divider>
                <span v-if="item.edu">
                  {{ item.edu }}
                </span>
                <span v-else> 学历不限 </span>
                <el-divider direction="vertical"></el-divider>
                <span v-if="item.exp">
                  {{ item.exp }}
                </span>
                <span v-else> 经验不限 </span>
                <el-divider direction="vertical"></el-divider>
                <span v-if="item.distype.length > 2">
                  <span>多种残疾</span>
                </span>
                <span
                    v-if="item.distype.length < 2 && item.distype.length != 0"
                >
                  <span v-for="(n, i) in item.distype.slice(0, 2)" :key="i">
                    {{ n.disType }}
                    <em v-if="n.disTypeLevel">/</em>
                    {{ n.disTypeLevel }}
                  </span>
                </span>
              </div>
              <div class="welfare line_clamp1">
                <span
                    v-if="item.jobLabels == null || item.jobLabels.length <= 0"
                >暂无福利标签</span
                >
                <span
                    v-for="(n, i) in item.jobLabels.slice(0, 3)"
                    :key="i"
                    v-else
                >{{ n.labelName }}</span
                >
              </div>
            </router-link>
          </div>
          <div
              class="bottom flex cursorP"
              @click="
              goDetail('/job/businessDetails?companyId=' + item.companyInfo.id)
            "
          >
            <img
                :src="item.companyInfo.logo || ''"
                alt=""
                :title="item.companyInfo.companyName"
                class="logo"
            />

            <div class="info">
              <h3 class="line_clamp1">
                {{ item.companyInfo.companyName }}
              </h3>
              <div class="type">
                <span>
                  {{ item.companyInfo.pr || "暂无企业性质" }}
                </span>
                <el-divider
                    direction="vertical"
                    v-if="item.companyInfo.pr"
                ></el-divider>
                <span>
                  {{ item.companyInfo.hyTop }}
                </span>
                <el-divider
                    direction="vertical"
                    v-if="item.companyInfo.hyTop"
                ></el-divider>
                <span>
                  {{ item.companyInfo.mun }}
                  <i></i>
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 猜您感兴趣的培训 -->
    <section
        class="free_train"
        v-loading="loading"
        element-loading-text="拼命加载中"
    >
      <div class="content">
        <div class="title relative">
          <h2>猜您感兴趣的培训</h2>
          <!-- <router-link @click="change()" class="all"> -->
          <router-link to="/training/trainList" class="p1 all"  target="_blank"
          >查看更多</router-link
          >
          <!-- </router-link> -->
        </div>
        <ul class="list flex flex-wrap">
          <li v-for="(n, i) in trainList" :key="i" class="box">
            <router-link
                :to="'/training/trainDetail?id=' + n.id"
                target="_blank"
                :title="n.trainName"
                class="name"
            >
              <div class="top relative">
                <img
                    :src="n.picurl"
                    alt=""
                    class="banner err_image"
                    :title="n.trainName"
                />
              </div>
              <img
                  src="@/static/train/registration.png"
                  alt=""
                  class="status"
                  v-if="n.ing == 2"
              />
              <img
                  src="@/static/train/over.png"
                  alt=""
                  class="status"
                  v-if="n.ing == 3"
              />
              <img
                  src="@/static/train/registered.png"
                  alt=""
                  class="status"
                  v-if="n.ing == 4"
              />
              <div class="bottom">
                <div class="time">
                  {{ n.appplyBeginTime + "-" + n.appplyEndTime }}
                </div>
                <img
                    src="@/static/select.png"
                    alt=""
                    class="img inline_block"
                    v-if="n.isTui == 'isTui'"
                />
                <span class="b_title">{{ n.trainName }}</span>
                <div class="address">
                  <ul>
                    <li class="line_clamp1" v-if="n.disType">
                      <span
                          v-for="(item, index) in JSON.parse(n.disType)"
                          :key="index"
                      >{{ item }}</span
                      >
                    </li>
                    <li class="line_clamp1" v-else>
                      <span>适合全部残疾类别</span>
                    </li>
                    <!-- <li>{{ n.maxNumber }}人报名</li> -->
                  </ul>
                  <i class="icon el-icon-location"></i> {{ n.trainAddress }}
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <status
            :type="status.type"
            v-if="status.isHide == 1"
            des="暂无相关培训"
        ></status>
        <!-- <div class="buttons">
          <router-link
            to="/training/trainList"
            target="_blank"
            title="查看更多培训"
            class="btns"
          >
            查看更多培训<i class="el-icon-arrow-right"></i>
          </router-link>
        </div> -->
      </div>
    </section>
    <!-- 猜你感兴趣的圈子问答 -->
    <askques></askques>
  </div>
</template>

<script>
import askques from "@/components/public/askQues";

export default {
  name: "index",
  data() {
    return {
      info: [],
      trainList: [],
      value: "1",
      loading: false,
      userType: "",
      memberId: "",
      basicList: [],
      countList: [],
      recomList: [],
      recomdList: [],
      form: {
        width: "70",
      },
      status: {
        isHide: 0,
        type: "",
      },
      search: {
        pageNum: 1,
        pageSize: 5,
        cityid: "",
      },
    };
  },
  components: {
    askques,
  },
  watch: {
    search: {
      handler(newName, oldName) {
        this.init();
      },
      deep: true,
    },
  },
  methods: {
    //推荐职位
    async init() {
      this.search.pageSize = 6;
      this.$axios
          .get("/api/app-jycy-job/querySearchJobInfo", this.search)
          .then((res) => {
            if (res.data.success) {
              this.info = res.data.data.records;
              console.log(this.info);
            } else {
            }
          })
          .catch((err) => {
            // that.loading = false;
          });
    },
    //猜您喜欢的培训
    async train(val) {
      this.loading = true;
      try {
        let res = await this.$api.trainListApi({
          pageNum: 1,
          pageSize: 3,
          trainType: val,
          isTui: "是",
        });
        this.loading = false;
        this.trainList = res.data.data.records;
        if (this.trainList.length <= 0) {
          this.status.isHide = 1;
          this.status.type = "result";
        } else {
          this.status.isHide = 0;
        }
      } catch (e) {
        this.loading = false;
        this.status.isHide = 1;
        this.status.type = "wifi";
      }
    },
    // 基本信息
    getMyDisAbledInfo() {
      if (localStorage.getItem('userInfo')){
        this.basicList = JSON.parse(localStorage.getItem('userInfo'));
      }
    },
    // 换一批
    goRouter(url, memberId, userType) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: memberId,
          userType: userType,
        },
      });
      window.open(href, "_blank");
    },
    windowRouter() {
      window.open("http://cjrcp.p.webtrn.cn/");
    },
  },

  created() {
    this.init();
    this.train();
    this.getMyDisAbledInfo();
    if (this.$store.state.login) {
      let memberId = this.$store.state.login.userInfo.id;
      this.memberId = memberId;
      let userType = this.$store.state.login.userInfo.userType;
      this.userType = userType;
    }
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/public.less";

//首页
.pages {
  padding-top: 40px;
  padding-bottom: 0;
}

.center {
  background: white;

  .header {
    padding: 30px 0;
    margin: 0 20px;
    border-bottom: 1px dashed @e1;

    .header_left {
      flex: 1;

      .imgs {
        width: 80px;
        height: 80px;
        border: 1px solid #dfdfdf;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        margin-right: 20px;
        overflow: hidden;

        .avatar {
          width: 100%;
          height: 100%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          margin-right: 20px;
          vertical-align: top;
        }
      }

      .info {
        li {
          display: flex;
          padding-top: 24px;
          align-items: center;
        }

        li {
          h1 {
            font-size: 20px;
            margin-right: 10px;
          }

          .select {
            width: 136px;
            margin-right: 10px;
          }

          .edit {
            img {
              margin-right: 5px;
            }

            color: @00;
          }
        }

        .edu {
          padding-top: 5px;

          span {
            span {
              color: @gray;
              padding: 0 10px;
            }
          }
        }

        .level {
          padding-top: 5px;
          color: #666666;
          font-size: 12px;

          span {
            padding: 0 2px;
          }
        }

        .address {
          padding-top: 0;
          color: #666666;
          font-size: 12px;
        }
      }
    }

    .header_right {
      padding-top: 20px;
      div {
        margin-right: 75px;
        h2 {
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #323232;
          line-height: 37px;
        }
        small {
          color: #666666;
          line-height: 20px;
          font-size: 14px;
          margin-left: 8px;
          margin-bottom: 24px;
        }
        p {
          color: #666666;
          line-height: 20px;
          font-size: 14px;
          letter-spacing: 0.47px;
          margin-top: 20px;
        }
      }
      .certify {
        display: inline-block;
        margin: 27px 0 0;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        width: 171px;
        height: 30px;
        background: #00924c;
        border-radius: 6px;
        line-height: 30px;
        text-align: center;
      }
    }
  }
  .title {
    h2 {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 28px;

      small {
        display: inline-block;
        color: #999999;
        font-size: 14px;
        font-weight: 400;
        padding-left: 25px;
        transform: translateY(-2px);
      }
    }

    .all {
      position: absolute;
      top: 2px;
      right: 0;
    }
    .all:hover {
      color: @00;
    }
  }
  .section_box {
    // padding-bottom: 10px;
    padding: 21px 0;
    margin: 0 49px;

    .title {
      h2 {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 28px;

        small {
          display: inline-block;
          color: #999999;
          font-size: 14px;
          font-weight: 400;
          padding-left: 25px;
          transform: translateY(-2px);
        }
      }

      .all {
        position: absolute;
        top: 2px;
        right: 0;
      }
      .all:hover {
        color: @00;
      }
    }

    .job {
      // margin-top: 12px;

      li {
        position: relative;
        width: 443px;
        height: 178px;
        border: 1px solid #e1e1e1;
        margin-top: 12px;
        margin-right: 15px;
        transition: all 0.4s;
        flex-direction: column;

        .top {
          margin: 0 17px;
          flex: 1;

          a {
            max-width: 443px;
            float: left;
            width: 100%;
          }

          .nameWrap {
            width: 100%;
            padding-top: 12px;
            //justify-content: space-between;
          }

          .name {
            font-size: 18px;
            max-width: 140px;
          }

          .jobtimes {
            color: #999999;
            font-size: 14px;
            // padding-left: 10px;
          }

          .money {
            position: absolute;
            right: 20px;
            color: #ff5500;
            font-size: 16px;
            font-weight: bold;
          }

          .address {
            padding-top: 8px;
            height: 30px;

            span {
              position: relative;
              color: #666666;
            }

            .el-divider--vertical:last-child {
              display: none;
            }
          }

          .welfare {
            height: 40px;
            padding: 8px 0;
            border-bottom: 1px dashed @ed;

            span {
              display: inline-block;
              font-size: 12px;
              padding: 0 10px;
              margin-right: 5px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              border-radius: 5px;
              color: @00;
              background: #e5faef;
            }
          }
        }

        .bottom {
          height: 64px;
          margin-top: 10px;
          padding: 0 18px;

          .logo {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            margin-top: 4px;
            margin-right: 12px;
          }

          .info {
            margin-top: 4px;

            h3 {
              max-width: 280px;
              font-size: 14px;
              font-weight: 400;
            }

            h3:hover {
              color: #00924b;
            }

            .type {
              font-size: 12px;
              color: @gray;

              span {
                position: relative;
              }

              .el-divider--vertical:last-child {
                display: none;
              }
            }
          }
        }
      }

      li:hover {
        transform: scale(1.05);
      }

      li:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
  .free_train {
    // background: @f7;
    // margin-top: 20px;
    padding: 21px 0;
    margin: 0 47px;
    .content {
      .tab {
        position: relative;
        padding: 38px 0;
        h1 {
          font-size: 32px;
          margin-right: 35px;
        }
        ul {
          flex: 1;
          li {
            padding: 5px 10px;
            margin-right: 30px;
            a {
              color: @66;
            }
            a:hover {
              color: @00;
            }
          }
          .active {
            color: white;
            background: @00;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            a {
              color: white;
            }
            a:hover {
              color: white;
            }
          }
        }
      }
      .list {
        margin-top: 20px;
        .box {
          position: relative;
          width: 280px;
          height: 325px;
          margin-right: 36px;
          margin-bottom: 20px;
          background: white;
          .top {
            width: 100%;
            height: 158px;
            overflow: hidden;
            text-align: center;
            img {
              width: 100%;
              height: 100%;
              // display: block;
              transition: all 1s;
            }
          }
          .status {
            position: absolute;
            top: 17px;
            right: -4px;
          }
          .bottom {
            padding: 0 10px;
            .time {
              font-size: 12px;
              color: #999999;
              padding-top: 5px;
            }
            span.b_title {
              font-size: 16px;
              line-height: 1.5em;
              padding-left: 10px;
            }
            .name {
              display: block;
              padding-top: 5px;
              img {
                margin-right: 5px;
              }
              font-size: 16px;
            }
            .address {
              position: absolute;
              left: 0;
              bottom: 10px;
              width: 100%;
              font-size: 12px;
              color: #999999;
              ul {
                display: flex;
                padding: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid @f7;
                li {
                  font-size: 12px;
                  span {
                    padding-right: 5px;
                  }
                }
                li:nth-of-type(1) {
                  flex: 8;
                  color: @00;
                }
                li:last-child {
                  color: @gray;
                }
              }
              i {
                margin-left: 10px;
              }
            }
          }
        }
        li:hover .top .banner {
          transform: scale(1.05);
        }
        li:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
